<template>
  <b-card title="">
    <b-row>
      <!-- {{ applicationOptions }} -->

      <b-col cols="6">
        <!-- <p>{{ token }}</p> -->
        <!-- <p>{{ applicationOptions }}</p> -->
        <h3 class="text-primary">Add Firewall</h3>
      </b-col>
      <b-col cols="6">
        <b-button
          variant="primary"
          class="btn btn-primary float-right"
          @click="back()"
          size="sm"
        >
          Back
        </b-button>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-row>
              <!-- first name firewallId-->
              <b-col cols="4">
                <b-form-group label="Firewall Name" label-for="v-first-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Firewall Name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-first-name"
                      placeholder="Firewall Name"
                      v-model="firewallName"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Firewall Id" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Firewall Id"
                    rules="required"
                  > -->
                  <b-form-input
                    id="v-first-name"
                    placeholder="Firewall Id"
                    v-model="firewallId"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Region" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Region"
                    rules="required"
                  > -->
                  <b-form-input
                    id="v-first-name"
                    placeholder="Region"
                    v-model="region"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Cloud Profile" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Cloud Profile"
                    rules="required"
                  > -->
                    <!-- <b-form-select v-model="cloudProfile.id">
                      <b-form-select-option
                        v-for="cloudprofile in cloudProfileOptions"
                        :key="cloudprofile.id"
                         :value="cloudprofile"
                        >{{ cloudprofile.name }}</b-form-select-option
                      >
                    </b-form-select> -->
                    <v-select
                      v-model="cloudProfile"
                      :options="cloudProfileOptions"
                      label="name"
                      taggable
                    />
                    <!-- {{ cloudProfile.id }} -->

                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Application" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Application List"
                    rules="required"
                  > -->
                  <v-select
                    v-model="applications"
                    multiple
                    :options="app_options"
                    label="application_name"
                    taggable
                  />

                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
                <!-- {{ applications }} -->
              </b-col>
              <!-- <b-col cols="4">
                <b-form-group label="Firewall Id" label-for="v-first-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Firewall Id"
                    rules="required"
                  >
                    <b-form-input
                      id="v-first-name"
                      placeholder="Firewall Id"
                      v-model="firewallId"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1 mt-1"
                  @click.prevent="addFirewall()"
                  :disabled="invalid"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
// import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      firewallName: "",
      firewallId: "",
      region: "",
      cloudProfile: "",
      cloudProfileOptions: [],
      cloudProfileOptionss: [],
      applications: [],
      token: "",
      items: [],
      applicationOptions: [],
      app_options: [],
    };
  },
  mounted() {
    this.getCloudList();
    this.fetchApplication();
    this.token = this.$store.state.app.token;
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
  },
  methods: {
    back() {
      this.$router.push("/ecafirewall");
    },
    getCloudList: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `cloudprofile/cloudprofile`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.cloudProfileOptionss = res.data;
          this.cloudProfileOptionss.forEach((ele) => {
            this.cloudProfileOptions.push(ele);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchApplication: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + "application/applications/",
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.applicationOptions = res.data.results;
        this.applicationOptions.forEach((ele) => {
          this.app_options.push(ele);
        });
      });
    },

    addFirewall() {
      var self = this;
      const body = {
        name: this.firewallName,
        firewall_id: this.firewallId,
        region: this.region,
        cloud_profile: this.cloudProfile.id,
        applications: this.applications,
      };
      // if (this.cloudProfile) {
      //   let cloud_list = [];
      //   cloud_list.push(this.cloudProfile);
      //   body["cloud_profile"] = cloud_list;
      // }
      const options = {
        method: "POST",
        data: body,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `firewall/firewall/`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status === "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Created Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push("/ecafirewall");
          }
        })
        .catch((err) => {
          console.log(err);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
